export const pagesRoutes = [
  ["v-8daa1a0e","/",{"title":""},["/index.html","/index.md"]],
  ["v-3d00c951","/archive/C_sharp.html",{"title":"C# SDK"},["/archive/C_sharp","/_archive/C_sharp.html","/_archive/C_sharp.md"]],
  ["v-15559070","/archive/android.html",{"title":"Kotlin SDK (Android)"},["/archive/android","/_archive/android.html","/_archive/android.md"]],
  ["v-c59e76dc","/archive/ios.html",{"title":"iOS UniqueSDK"},["/archive/ios","/_archive/ios.html","/_archive/ios.md"]],
  ["v-843656b4","/todo/collection-schemas.html",{"title":"Collection schemas"},["/todo/collection-schemas","/_todo/collection-schemas.html","/_todo/collection-schemas.md"]],
  ["v-7c971834","/todo/feature-feautures.html",{"title":""},["/todo/feature-feautures","/_todo/feature-feautures.html","/_todo/feature-feautures.md"]],
  ["v-9311485c","/todo/governance-only.html",{"title":"Governance-only Calls"},["/todo/governance-only","/_todo/governance-only.html","/_todo/governance-only.md"]],
  ["v-02db6f96","/about/approach.html",{"title":"NFT 2.0 approach"},["/about/approach","/about/approach.md"]],
  ["v-74bc627b","/about/",{"title":"About"},["/about/index.html","/about/index.md"]],
  ["v-f864654e","/about/networks.html",{"title":"Networks"},["/about/networks","/about/networks.md"]],
  ["v-1d52aa9d","/about/timeline.html",{"title":"Timeline"},["/about/timeline","/about/timeline.md"]],
  ["v-777a1bba","/build/",{"title":"Welcome to the building section"},["/build/index.html","/build/index.md"]],
  ["v-807a9f1c","/products/",{"title":"Products"},["/products/index.html","/products/index.md"]],
  ["v-22a2f9fd","/reference/",{"title":"Networks list"},["/reference/index.html","/reference/index.md"]],
  ["v-383a74cb","/reference/sdk-endpoints.html",{"title":"Public endpoints"},["/reference/sdk-endpoints","/reference/sdk-endpoints.md"]],
  ["v-803929d2","/reference/sdk-methods.html",{"title":"Methods"},["/reference/sdk-methods","/reference/sdk-methods.md"]],
  ["v-75b1c99b","/reference/tools.html",{"title":"Tools"},["/reference/tools","/reference/tools.md"]],
  ["v-6fe97cbf","/tutorials/burnNFT.html",{"title":"Burn an NFT"},["/tutorials/burnNFT","/tutorials/burnNFT.md"]],
  ["v-5a73a231","/tutorials/create-collection-token.html",{"title":"Create a collection and mint a token"},["/tutorials/create-collection-token","/tutorials/create-collection-token.md"]],
  ["v-5859a8db","/tutorials/createAccount.html",{"title":"Creating Substrate Address via browser extension"},["/tutorials/createAccount","/tutorials/createAccount.md"]],
  ["v-53d57b14","/tutorials/destroyCollection.html",{"title":"Destroy a collection"},["/tutorials/destroyCollection","/tutorials/destroyCollection.md"]],
  ["v-d7d04ef6","/tutorials/easy-market.html",{"title":"How to create a marketplace from scratch"},["/tutorials/easy-market","/tutorials/easy-market.md"]],
  ["v-26802bee","/tutorials/examplesREST.html",{"title":"REST API examples"},["/tutorials/examplesREST","/tutorials/examplesREST.md"]],
  ["v-6f4cf9aa","/tutorials/getAccountAndBalance.html",{"title":"Get and display accounts and balances"},["/tutorials/getAccountAndBalance","/tutorials/getAccountAndBalance.md"]],
  ["v-644c2562","/tutorials/getNFTsAndTokens.html",{"title":"How to get and display NFTs and collections"},["/tutorials/getNFTsAndTokens","/tutorials/getNFTsAndTokens.md"]],
  ["v-024f6100","/tutorials/graph-node-docker.html",{"title":"The Graph"},["/tutorials/graph-node-docker","/tutorials/graph-node-docker.md"]],
  ["v-032528e4","/tutorials/how-to-get-collections-tokens.html",{"title":"ScanAPI. How to get collections and tokens."},["/tutorials/how-to-get-collections-tokens","/tutorials/how-to-get-collections-tokens.md"]],
  ["v-61b606f9","/tutorials/how-to-use-nesting-and-bundling.html",{"title":"How to use nesting and bundling"},["/tutorials/how-to-use-nesting-and-bundling","/tutorials/how-to-use-nesting-and-bundling.md"]],
  ["v-0efe29b3","/tutorials/",{"title":"Welcome to the tutorials section"},["/tutorials/index.html","/tutorials/index.md"]],
  ["v-279c6d0e","/tutorials/live-nft-example.html",{"title":"Live NFT example"},["/tutorials/live-nft-example","/tutorials/live-nft-example.md"]],
  ["v-4558477e","/tutorials/mass-transactions.html",{"title":"Bulk Transactions"},["/tutorials/mass-transactions","/tutorials/mass-transactions.md"]],
  ["v-699eee64","/tutorials/nfts-fetching.html",{"title":"Fetching NFT"},["/tutorials/nfts-fetching","/tutorials/nfts-fetching.md"]],
  ["v-0bf9031b","/tutorials/nfts-how-to-create-and-tune-collection.html",{"title":"How to create and setup a collection"},["/tutorials/nfts-how-to-create-and-tune-collection","/tutorials/nfts-how-to-create-and-tune-collection.md"]],
  ["v-7823b045","/tutorials/nfts-how-to-mint.html",{"title":"Mint an NFT using schemas"},["/tutorials/nfts-how-to-mint","/tutorials/nfts-how-to-mint.md"]],
  ["v-1f8b7eb1","/tutorials/nfts-ways-to-create.html",{"title":"(Duplicate) Substrate Client. Different approaches to create a collection"},["/tutorials/nfts-ways-to-create","/tutorials/nfts-ways-to-create.md"]],
  ["v-199cae3e","/tutorials/store-files.html",{"title":"(Duplicate) How to store files"},["/tutorials/store-files","/tutorials/store-files.md"]],
  ["v-4da8f28c","/tutorials/subquery-indexer.html",{"title":"SubQuery Indexer"},["/tutorials/subquery-indexer","/tutorials/subquery-indexer.md"]],
  ["v-901172a8","/tutorials/wallet-integration-old.html",{"title":"Wallet integration"},["/tutorials/wallet-integration-old","/tutorials/wallet-integration-old.md"]],
  ["v-4d02efc6","/tutorials/wallet-integration.html",{"title":"Wallet integration"},["/tutorials/wallet-integration","/tutorials/wallet-integration.md"]],
  ["v-295a269c","/tutorials/websocket-subscriptions.html",{"title":"Websocket subscriptions"},["/tutorials/websocket-subscriptions","/tutorials/websocket-subscriptions.md"]],
  ["v-a6bd95d6","/tutorials/work-with-accounts.html",{"title":"Cookbook for working with accounts and signers"},["/tutorials/work-with-accounts","/tutorials/work-with-accounts.md"]],
  ["v-2ea7e06d","/tutorials/work-with-evm-via-sdk.html",{"title":"EVM in SDK"},["/tutorials/work-with-evm-via-sdk","/tutorials/work-with-evm-via-sdk.md"]],
  ["v-5ed06074","/todo/ecosystem/polkadot.html",{"title":"Polkadot and Kusama"},["/todo/ecosystem/polkadot","/_todo/ecosystem/polkadot.html","/_todo/ecosystem/polkadot.md"]],
  ["v-08bd9a92","/todo/ecosystem/substrate.html",{"title":"Substrate"},["/todo/ecosystem/substrate","/_todo/ecosystem/substrate.html","/_todo/ecosystem/substrate.md"]],
  ["v-60c36fc6","/todo/network-features/did.html",{"title":"Decentralized IDs"},["/todo/network-features/did","/_todo/network-features/did.html","/_todo/network-features/did.md"]],
  ["v-2866c13c","/todo/network-features/onchain-metadata.html",{"title":"Onchain metadata"},["/todo/network-features/onchain-metadata","/_todo/network-features/onchain-metadata.html","/_todo/network-features/onchain-metadata.md"]],
  ["v-1f351892","/todo/network-features/rate-limits.html",{"title":"Rate limits"},["/todo/network-features/rate-limits","/_todo/network-features/rate-limits.html","/_todo/network-features/rate-limits.md"]],
  ["v-21539485","/todo/network-features/refungibility.html",{"title":"Refungibility"},["/todo/network-features/refungibility","/_todo/network-features/refungibility.html","/_todo/network-features/refungibility.md"]],
  ["v-bb03485c","/todo/network-features/royalties.html",{"title":"Royalties"},["/todo/network-features/royalties","/_todo/network-features/royalties.html","/_todo/network-features/royalties.md"]],
  ["v-904b03fc","/todo/network-features/scheduling.html",{"title":"Scheduling"},["/todo/network-features/scheduling","/_todo/network-features/scheduling.html","/_todo/network-features/scheduling.md"]],
  ["v-438d0d86","/todo/schemas/ERC721Metadata.html",{"title":"ERC721Metadata schema"},["/todo/schemas/ERC721Metadata","/_todo/schemas/ERC721Metadata.html","/_todo/schemas/ERC721Metadata.md"]],
  ["v-ce97a68a","/todo/schemas/unique.html",{"title":"Unique Schema 1.0.0"},["/todo/schemas/unique","/_todo/schemas/unique.html","/_todo/schemas/unique.md"]],
  ["v-70956a15","/about/network-features/evm.html",{"title":"EVM and Smart Contracts"},["/about/network-features/evm","/about/network-features/evm.md"]],
  ["v-850b81f4","/about/network-features/marketplace.html",{"title":"Unique Network Marketplace"},["/about/network-features/marketplace","/about/network-features/marketplace.md"]],
  ["v-17da2b2a","/about/network-features/pricing.html",{"title":"Pricing"},["/about/network-features/pricing","/about/network-features/pricing.md"]],
  ["v-18fc2785","/about/network-features/sdk-indexer.html",{"title":"SDK and Indexer"},["/about/network-features/sdk-indexer","/about/network-features/sdk-indexer.md"]],
  ["v-d8d50a8e","/about/network-features/sponsoring.html",{"title":"Sponsoring"},["/about/network-features/sponsoring","/about/network-features/sponsoring.md"]],
  ["v-34c92f1a","/about/network-features/staking.html",{"title":"Staking"},["/about/network-features/staking","/about/network-features/staking.md"]],
  ["v-1f52b5f4","/about/nft-features/composable.html",{"title":"Composable NFTs"},["/about/nft-features/composable","/about/nft-features/composable.md"]],
  ["v-9d4cbf92","/about/nft-features/customizable.html",{"title":"Customizable NFTs"},["/about/nft-features/customizable","/about/nft-features/customizable.md"]],
  ["v-319a1920","/about/nft-features/dynamic.html",{"title":"Dynamic NFTs"},["/about/nft-features/dynamic","/about/nft-features/dynamic.md"]],
  ["v-32983e88","/about/nft-features/multimedia.html",{"title":"Multimedia NFTs"},["/about/nft-features/multimedia","/about/nft-features/multimedia.md"]],
  ["v-af6a7d88","/about/nft-features/native.html",{"title":"Native NFTs"},["/about/nft-features/native","/about/nft-features/native.md"]],
  ["v-524b8748","/about/nft-features/nested.html",{"title":"Nested NFTs"},["/about/nft-features/nested","/about/nft-features/nested.md"]],
  ["v-5c8fcf95","/about/nft-formats/dynamic-nfts.html",{"title":""},["/about/nft-formats/dynamic-nfts","/about/nft-formats/dynamic-nfts.md"]],
  ["v-2b9a5a08","/about/token-types/coins.html",{"title":"Coins"},["/about/token-types/coins","/about/token-types/coins.md"]],
  ["v-e97a5262","/about/token-types/main-types.html",{"title":"Overview"},["/about/token-types/main-types","/about/token-types/main-types.md"]],
  ["v-5482727c","/about/token-types/nft.html",{"title":"NFTs"},["/about/token-types/nft","/about/token-types/nft.md"]],
  ["v-75437a3e","/about/token-types/rft.html",{"title":"Fractional NFTs"},["/about/token-types/rft","/about/token-types/rft.md"]],
  ["v-2944117b","/build/evm/UniqueNFT.html",{"title":"UniqueNFT"},["/build/evm/UniqueNFT","/build/evm/UniqueNFT.md"]],
  ["v-34149d74","/build/evm/accounts.html",{"title":"Account"},["/build/evm/accounts","/build/evm/accounts.md"]],
  ["v-9d3d3ee6","/build/evm/",{"title":"Intro to Unique EVM"},["/build/evm/index.html","/build/evm/index.md"]],
  ["v-171404f7","/build/evm/todo-setting-project.html",{"title":""},["/build/evm/todo-setting-project","/build/evm/todo-setting-project.md"]],
  ["v-5408c100","/build/sdk/about_sdk.html",{"title":"What is the SDK (or Unique Network connection tools)"},["/build/sdk/about_sdk","/build/sdk/about_sdk.md"]],
  ["v-817131b8","/build/sdk/accounts.html",{"title":"Working with accounts"},["/build/sdk/accounts","/build/sdk/accounts.md"]],
  ["v-57971552","/build/sdk/architecture.html",{"title":"Process layout"},["/build/sdk/architecture","/build/sdk/architecture.md"]],
  ["v-61b60c53","/build/sdk/collections.html",{"title":"Working with collections"},["/build/sdk/collections","/build/sdk/collections.md"]],
  ["v-49536104","/build/sdk/examples-nesting.html",{"title":"Nesting"},["/build/sdk/examples-nesting","/build/sdk/examples-nesting.md"]],
  ["v-e6af5aaa","/build/sdk/examplesCustomExtrinsic.html",{"title":"Universal call of chain methods via SDK"},["/build/sdk/examplesCustomExtrinsic","/build/sdk/examplesCustomExtrinsic.md"]],
  ["v-5d3b9f11","/build/sdk/examplesLifeNFT.html",{"title":"Live NFT"},["/build/sdk/examplesLifeNFT","/build/sdk/examplesLifeNFT.md"]],
  ["v-dd9ff208","/build/sdk/examplesSubstrateREST.html",{"title":"REST API examples"},["/build/sdk/examplesSubstrateREST","/build/sdk/examplesSubstrateREST.md"]],
  ["v-0b7e2150","/build/sdk/getting-started.html",{"title":"Getting started"},["/build/sdk/getting-started","/build/sdk/getting-started.md"]],
  ["v-fcab5e46","/build/sdk/refungible.html",{"title":"Re-Fungible"},["/build/sdk/refungible","/build/sdk/refungible.md"]],
  ["v-7b532cfc","/build/sdk/sponsoring.html",{"title":"Transaction sponsoring"},["/build/sdk/sponsoring","/build/sdk/sponsoring.md"]],
  ["v-380a7630","/build/sdk/tokens.html",{"title":"Working with tokens"},["/build/sdk/tokens","/build/sdk/tokens.md"]],
  ["v-d756f9e6","/build/sdk/tools.html",{"title":"Tools"},["/build/sdk/tools","/build/sdk/tools.md"]],
  ["v-b5b9827e","/products/market/",{"title":"Market"},["/products/market/index.html","/products/market/index.md"]],
  ["v-9ad48664","/products/minter/",{"title":"Minter"},["/products/minter/index.html","/products/minter/index.md"]],
  ["v-31125fc0","/products/scan/",{"title":"Scan"},["/products/scan/index.html","/products/scan/index.md"]],
  ["v-946512b8","/products/wallet/",{"title":"Wallet"},["/products/wallet/index.html","/products/wallet/index.md"]],
  ["v-669452a2","/reference/blockchain/collections.html",{"title":"Collection data"},["/reference/blockchain/collections","/reference/blockchain/collections.md"]],
  ["v-66883ad1","/reference/blockchain/contract-helpers.html",{"title":"Contract Helpers"},["/reference/blockchain/contract-helpers","/reference/blockchain/contract-helpers.md"]],
  ["v-79df933c","/reference/blockchain/events.html",{"title":"Unique Pallet - Events"},["/reference/blockchain/events","/reference/blockchain/events.md"]],
  ["v-4c646ebf","/reference/blockchain/extrinsics.html",{"title":"Unique Pallet - Extrinsics"},["/reference/blockchain/extrinsics","/reference/blockchain/extrinsics.md"]],
  ["v-94f46c66","/reference/blockchain/nesting.html",{"title":"Nesting"},["/reference/blockchain/nesting","/reference/blockchain/nesting.md"]],
  ["v-26093d12","/reference/blockchain/owner-admin-roles.html",{"title":"Roles and access rights"},["/reference/blockchain/owner-admin-roles","/reference/blockchain/owner-admin-roles.md"]],
  ["v-bd5e8cf0","/reference/blockchain/properties.html",{"title":"Collection and Token Properties"},["/reference/blockchain/properties","/reference/blockchain/properties.md"]],
  ["v-f7ffe35c","/reference/blockchain/rpc.html",{"title":"Unique Pallet - RPC calls"},["/reference/blockchain/rpc","/reference/blockchain/rpc.md"]],
  ["v-4ea60c7a","/reference/schemas/",{"title":"Unique Schema"},["/reference/schemas/index.html","/reference/schemas/index.md"]],
  ["v-74779f87","/tutorials/accounts/create-account.html",{"title":"Create an account"},["/tutorials/accounts/create-account","/tutorials/accounts/create-account.md"]],
  ["v-63c7a3da","/tutorials/accounts/integrate-creating-into-UI.html",{"title":"Create an account via web form"},["/tutorials/accounts/integrate-creating-into-UI","/tutorials/accounts/integrate-creating-into-UI.md"]],
  ["v-15065cf4","/tutorials/createCollectionV2/",{"title":"Mint an NFT using Unique Schema v2"},["/tutorials/createCollectionV2/index.html","/tutorials/createCollectionV2/index.md"]],
  ["v-5dd742ab","/tutorials/evm/eth-general.html",{"title":"General info"},["/tutorials/evm/eth-general","/tutorials/evm/eth-general.md"]],
  ["v-2b1cb35a","/tutorials/evm/how-to-ethereum.html",{"title":"How to work with Unique Network using Ethereum technologies"},["/tutorials/evm/how-to-ethereum","/tutorials/evm/how-to-ethereum.md"]],
  ["v-ef190240","/tutorials/evm/ready-samples.html",{"title":"Ready to use samples"},["/tutorials/evm/ready-samples","/tutorials/evm/ready-samples.md"]],
  ["v-57b98f47","/tutorials/evm/using-contracts.html",{"title":"Using your own smart contract"},["/tutorials/evm/using-contracts","/tutorials/evm/using-contracts.md"]],
  ["v-84d1531a","/tutorials/evm/using-sol-interfaces.html",{"title":"Using Solidity interfaces library"},["/tutorials/evm/using-sol-interfaces","/tutorials/evm/using-sol-interfaces.md"]],
  ["v-00fb0253","/tutorials/minting/customizable-nfts.html",{"title":"Customizable NFTs guide"},["/tutorials/minting/customizable-nfts","/tutorials/minting/customizable-nfts.md"]],
  ["v-980f3600","/tutorials/minting/generative-nft.html",{"title":"Generative NFT Guide"},["/tutorials/minting/generative-nft","/tutorials/minting/generative-nft.md"]],
  ["v-7fccee5e","/tutorials/minting/main.html",{"title":"Welcome to the minting guides section"},["/tutorials/minting/main","/tutorials/minting/main.md"]],
  ["v-1f180cac","/tutorials/minting/mass-listing.html",{"title":"Mass listing on Unique Market"},["/tutorials/minting/mass-listing","/tutorials/minting/mass-listing.md"]],
  ["v-615a7c70","/tutorials/minting/mass-minting.html",{"title":"Mass NFT minting guide"},["/tutorials/minting/mass-minting","/tutorials/minting/mass-minting.md"]],
  ["v-67346f74","/tutorials/minting/prepare-account.html",{"title":"Getting Account and OPL Tokens"},["/tutorials/minting/prepare-account","/tutorials/minting/prepare-account.md"]],
  ["v-06a07d0e","/tutorials/minting/prepare-scripts.html",{"title":"Preparing scripts"},["/tutorials/minting/prepare-scripts","/tutorials/minting/prepare-scripts.md"]],
  ["v-0d4ca118","/tutorials/minting/setup-environment.html",{"title":"Setting up an environment"},["/tutorials/minting/setup-environment","/tutorials/minting/setup-environment.md"]],
  ["v-8a185bac","/tutorials/user-guides/ledger-connect.html",{"title":"How to connect Ledger to Wallet"},["/tutorials/user-guides/ledger-connect","/tutorials/user-guides/ledger-connect.md"]],
  ["v-340ea201","/tutorials/user-guides/polkadotjs.html",{"title":"Polkadot.js browser extension"},["/tutorials/user-guides/polkadotjs","/tutorials/user-guides/polkadotjs.md"]],
  ["v-58c12348","/tutorials/user-guides/wallets.html",{"title":"Choose your wallet"},["/tutorials/user-guides/wallets","/tutorials/user-guides/wallets.md"]],
  ["v-d1a0a4b8","/build/evm/smart-contracts/collection-helpers.html",{"title":"Collection Helpers"},["/build/evm/smart-contracts/collection-helpers","/build/evm/smart-contracts/collection-helpers.md"]],
  ["v-6b79cbf0","/build/evm/smart-contracts/contract-helpers.html",{"title":"Contract Helpers"},["/build/evm/smart-contracts/contract-helpers","/build/evm/smart-contracts/contract-helpers.md"]],
  ["v-206d57bf","/build/evm/smart-contracts/",{"title":"Intro"},["/build/evm/smart-contracts/index.html","/build/evm/smart-contracts/index.md"]],
  ["v-2f3fdb68","/build/evm/smart-contracts/writing-contracts.html",{"title":"Writing smart contracts"},["/build/evm/smart-contracts/writing-contracts","/build/evm/smart-contracts/writing-contracts.md"]],
  ["v-8c6ba084","/build/sdk/v2/balances.html",{"title":"Balances"},["/build/sdk/v2/balances","/build/sdk/v2/balances.md"]],
  ["v-73a45548","/build/sdk/v2/collections.html",{"title":"Collections"},["/build/sdk/v2/collections","/build/sdk/v2/collections.md"]],
  ["v-ff8a44b2","/build/sdk/v2/dapps.html",{"title":"Building DApps"},["/build/sdk/v2/dapps","/build/sdk/v2/dapps.md"]],
  ["v-093ea961","/build/sdk/v2/evm.html",{"title":"EVM and Smart Contracts"},["/build/sdk/v2/evm","/build/sdk/v2/evm.md"]],
  ["v-9a8453c4","/build/sdk/v2/indexer.html",{"title":"Unique SDK indexer"},["/build/sdk/v2/indexer","/build/sdk/v2/indexer.md"]],
  ["v-0b8f2baf","/build/sdk/v2/migration.html",{"title":"Migration to SDK-2.0"},["/build/sdk/v2/migration","/build/sdk/v2/migration.md"]],
  ["v-341f2e7b","/build/sdk/v2/quick-start.html",{"title":"Unique SDK quick start"},["/build/sdk/v2/quick-start","/build/sdk/v2/quick-start.md"]],
  ["v-489ea426","/build/sdk/v2/sponsoring.html",{"title":"Collection sponsoring"},["/build/sdk/v2/sponsoring","/build/sdk/v2/sponsoring.md"]],
  ["v-3f75a81b","/build/sdk/v2/tokens.html",{"title":"NFTs"},["/build/sdk/v2/tokens","/build/sdk/v2/tokens.md"]],
  ["v-8cfdeb58","/build/tech-concepts/addresses/",{"title":"Addresses: Substrate & EVM"},["/build/tech-concepts/addresses/index.html","/build/tech-concepts/addresses/index.md"]],
  ["v-481a5791","/build/tech-concepts/balances/",{"title":"Balances"},["/build/tech-concepts/balances/index.html","/build/tech-concepts/balances/index.md"]],
  ["v-3706649a","/404.html",{"title":""},["/404"]],
]
